// @flow
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { LANDING } from "./locales/namespaces";
import Hero from "./components/landing/Hero";
import MobileApp from "./components/landing/MobileApp";
import BankLogos from "./components/landing/BankLogos";
import Reviews from "./components/landing/Reviews";
import Footer from "./components/landing/Footer";

const Landing = () => {
  const { t } = useTranslation(LANDING);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.setAttribute("data-use-service-core", "");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("IMS FC - Online Mortgage Loan")}</title>
        <meta
          name="description"
          content={t("Online mortgage loan from IMS. Discover specialized financing solutions for foreign residents from the largest credit broker in Greece. Complete the entire process from your mobile!")}
        />
        <meta
          property="og:title"
          content={t("IMS FC - Online Mortgage Loan")}
        />
        <meta
          property="og:description"
          content={t("Online mortgage loan from IMS. Discover specialized financing solutions for foreign residents from the largest credit broker in Greece. Complete the entire process from your mobile!")}
        />
      </Helmet>
      <style>{`body { background-color: #fff; }`}</style>
      <Hero />
      <MobileApp />
      <BankLogos />
      <Reviews />
      <Footer />
    </>
  )
}

export default Landing;
