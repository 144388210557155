import {Box, Typography, List, ListItem, ListItemText, Link, Stack, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import React, { useEffect, useState } from "react";
import MainCard from "../../theme/mantis/components/mainCard";
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";

const PrivacyPolicy = ({handleCloseModal, isModal}) => {
	const {t} = useTranslation(PAGE_LOGIN)
	const [isListVisible, setListVisible] = useState(false);
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Privacy Policy')
	}, [t])
	
	const listVisibility = () => {
		setListVisible(!isListVisible);
	};
	const openLink = async () => {
		if (Capacitor.isNativePlatform()) {
			// Open link in in-app browser on native app
			await Browser.open({url: 'https://cookiedatabase.org/legal-bases/#consent'});
		} else {
			// Open link in new tab in web app
			window.open('https://cookiedatabase.org/legal-bases/#consent', '_blank');
		}
	};
	const openIMSLink = async () => {
		if (Capacitor.isNativePlatform()) {
			// Open link in in-app browser on native app
			await Browser.open({url: 'https://www.ims-fc.gr'});
		} else {
			// Open link in new tab in web app
			window.open('https://www.ims-fc.gr', '_blank');
		}
	};
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-end'
			}}
		>
			{isModal ?
				<Button
					sx={{
						m: 1,
						textAlign: 'right'
					}}
					//variant={'outlined'}
					onClick={handleCloseModal}
					color={'blue'}
				>
					{t("X")}
				</Button>
				: null
			}
			<MainCard
				title={
					<Typography variant="h4">
						{t('Privacy Statement')}
					</Typography>
				}
				sx={{
					mt: isModal ? 0 : 2
				}}
			>
				<Typography
					variant="h5">{t('This privacy statement was last updated on 26 January 2023 and applies to citizens and legal permanent residents of the European Economic Area and Switzerland.')}</Typography>
				<Typography
					variant="body">{t('this_privacy_statement')}</Typography>
				<Typography variant="h5" color="text.secondary"
							component={'div'}>
					<List
						sx={{
							listStyleType: 'disc',
							pl: 2,
							'& .MuiListItem-root': {
								display: 'list-item',
								color: '#485776'
							},
							ml: 1
						}}
					>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we clearly state the purposes for which we process personal data. We do this by means of this privacy statement;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we aim to limit our collection of personal data to only the personal data required for legitimate purposes;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we first request your explicit consent to process your personal data in cases requiring your consent;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we take appropriate security measures to protect your personal data and also require this from parties that process personal data on our behalf;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we respect your right to access your personal data or have it corrected or deleted, at your request.')}/>
						</ListItem>
					</List>
				</Typography>
				<Typography
					variant={'h5'}>{t('If you have any questions, or want to know exactly what data we keep of you, please contact us.')}</Typography>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('1. Purpose, data and retention period')}
					</Typography>
					<Typography
						variant={'h5'}>{t('personal_information')}</Typography>
					<Link
						component={'button'}
						onClick={listVisibility}
						rel="noopener"
						underline="hover"
						sx={{
							color: '#485776',
							fontSize: '14px',
							fontStyle: 'normal',
							lineHeight: '22px',
							textDecorationLine: 'underline'
						}}
					>
						<Typography
							variant={'h5'}
							fontWeight={700}
						>
							{t('1.1 Contact - Through phone, mail, email and/or webforms')}
						</Typography>
					</Link>
					{isListVisible && (
						<Box sx={{mt: 1}}>
							<Typography variant={'body'}>{t('following_data')}</Typography>
							<Typography
								variant="h5"
								color="text.secondary"
								component={'div'}
							>
								<List
									sx={{
										listStyleType: 'disc',
										pl: 2,
										'& .MuiListItem-root': {
											display: 'list-item',
											color: '#485776'
										},
										ml: 1
									}}
								>
									<ListItem disablePadding>
										<ListItemText
											primary={t('A first and last name')}/>
									</ListItem>
									<ListItem disablePadding>
										<ListItemText
											primary={t('A home or other physical address, including street name and name of a city or town')}/>
									</ListItem>
									<ListItem disablePadding>
										<ListItemText
											primary={t('An email address')}/>
									</ListItem>
									<ListItem disablePadding>
										<ListItemText
											primary={t('Geolocation data')}/>
									</ListItem>
									<ListItem disablePadding>
										<ListItemText
											primary={t('Financial information such as bank account number or credit card number')}/>
									</ListItem>
								</List>
							</Typography>
							<Typography variant={'h5'}>{t('process_data')}</Typography>
							<Link
								component="button"
								onClick={openLink}
								rel="noopener"
								underline="hover"
								color={'#485776'}
								sx={{
									textDecorationLine: 'underline'
								}}
							>
								{t('Upon the provision of consent.')}
							</Link>
							<Typography variant={'h5'}>{t('Retention period')}</Typography>
							<Typography
								variant={'h5'}>{t('We retain this data until the service is terminated.')}</Typography>
						</Box>
					)}
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('2. Cookies')}
					</Typography>
					<Typography
						variant={'h5'}>{t('Our website uses cookies. For more information about cookies, please refer to our Cookie Policy.')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('3. Disclosure practices')}
					</Typography>
					<Typography
						variant={'h5'}>{t('We disclose personal information if we are required by law or by a court order, in response to a law enforcement agency, to the extent permitted under other provisions of law, to provide information, or for an investigation on a matter related to public safety.')}</Typography>
					<Typography
						variant={'h5'}>{t('If our website or organisation is taken over, sold, or involved in a merger or acquisition, your details may be disclosed to our advisers and any prospective purchasers and will be passed on to the new owners.')}</Typography>
					<Typography
						variant={'h5'}>{t('We have concluded a data Processing Agreement with Google.')}</Typography>
					<Typography
						variant={'h5'}>{t('The inclusion of full IP addresses is blocked by us.')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('4. Security')}
					</Typography>
					<Typography
						variant={'h5'}>{t('We are committed to the security of personal data. We take appropriate security measures to limit abuse of and unauthorized access to personal data. This ensures that only the necessary persons have access to your data, that access to the data is protected, and that our security measures are regularly reviewed.')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('5. Third-party websites')}
					</Typography>
					<Typography
						variant={'h5'}>{t('This privacy statement does not apply to third-party websites connected by links on our website. We cannot guarantee that these third parties handle your personal data in a reliable or secure manner. We recommend you read the privacy statements of these websites prior to making use of these websites.')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('6. Amendments to this privacy statement')}
					</Typography>
					<Typography
						variant={'h5'}>{t('We reserve the right to make amendments to this privacy statement. It is recommended that you consult this privacy statement regularly in order to be aware of any changes. In addition, we will actively inform you wherever possible.')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('7. Accessing and modifying your data')}
					</Typography>
					<Typography
						variant={'h5'}>{t('following_rights')}</Typography>
					<Typography variant="body2" color="text.secondary" style={{fontSize: '1rem'}}
								component={'div'}>
						<List
							sx={{
								listStyleType: 'disc',
								pl: 2,
								'& .MuiListItem-root': {
									display: 'list-item',
									color: '#485776'
								},
								ml: 1
							}}
						>
							<ListItem disablePadding>
								<ListItemText
									primary={t('You have the right to know why your personal data is needed, what will happen to it, and how long it will be retained for.')}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText primary={t('right_access')}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText primary={t('right_rectification')}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText
									primary={t('If you give us your consent to process your data, you have the right to revoke that consent and to have your personal data deleted.')}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText primary={t('right_transfer')}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText primary={t('right_object')}/>
							</ListItem>
						</List>
					</Typography>
					<Typography
						variant={'h5'}>{t('Please make sure to always clearly state who you are, so that we can be certain that we do not modify or delete any data of the wrong person.')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('8. Submitting a complaint')}
					</Typography>
					<Typography
						variant={'h5'}>{t('If you are not satisfied with the way in which we handle (a complaint about) the processing of your personal data, you have the right to submit a complaint to the Data Protection Authority.')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('9. Data Protection Officer')}
					</Typography>
					<Typography
						variant={'h5'}>{t('Our Data Protection Officer has been registered with the data protection authority in an EU Member State. If you have any questions or requests with respect to this privacy statement or for the Data Protection Officer, you can contact via www.ims-fc.gr or dpo@ims-fc.gr or by phone at 2109513111')}</Typography>
				</Box>
				<Box sx={{mt: 1}}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('10. Contact details')}
					</Typography>
					<Typography variant={'h5'}>{t('K. KAMPOURIS – I. MAVRAKIS S.A.')}</Typography>
					<Typography variant={'h5'}>{t('240 Andrea Syggrou Street, PO Box 176 72 Kallithea')}</Typography>
					<Typography variant={'h5'}>{t('Greece')}</Typography>
					<Stack direction={'row'} spacing={1}>
						<Typography variant={'h5'}>{t('website')}</Typography>
						<Link
							component="button"
							onClick={openIMSLink}
							rel="noopener"
							underline="hover"
							color={'#485776'}
							sx={{
								textDecorationLine: 'underline',
								fontWeight: 700
							}}
						>
							{t('ims-fc.gr')}
						</Link>
					</Stack>
					<Typography variant={'h5'}>{t('privacy_email')}</Typography>
					<Typography variant={'h5'}>{t('phone_number')}</Typography>
				</Box>
			</MainCard>
		</Box>
	)
}

export default PrivacyPolicy;