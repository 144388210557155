import * as Namespaces from "./namespaces"

const locales = {
	[Namespaces.LANDING]: {
		el: {
			"IMS FC - Online Mortgage Loan": "IMS FC - Στεγαστικό Δάνειο Online",
			"Online mortgage loan from IMS. Discover specialized financing solutions for foreign residents from the largest credit broker in Greece. Complete the entire process from your mobile!": "Online Στεγαστικό δάνειο από την IMS. Ανακαλύψτε εξειδικευμένες λύσεις χρηματοδότησης από το μεγαλύτερο μεσίτη πιστώσεων στην Ελλάδα. Όλη η διαδικασία από το κινητό σας!",
			"Log In": "Σύνδεση",
			"Sign Up": "Εγγραφή",
			"Apply Now": "Online Αίτηση",
			"You can now start your mortgage application online!": "Ξεκίνα άμεσα την αίτηση για στεγαστικό δάνειο online!",
			"IMS, the largest mortgage broker in Greece, guides you to secure your loan with speed and ease.": "Η IMS, ο μεγαλύτερος διαμεσολαβητής έκδοσης στεγαστικών δανείων στην Ελλάδα, σε καθοδηγεί ώστε να εξασφαλίσεις το δάνειό σου με ταχύτητα και ευκολία.",
			"All IMS services are free of charge with no additional cost for the borrower.": "Όλες οι υπηρεσίες της  IMS είναι  δωρεάν χωρίς καμία επιπλέον χρέωση για τον δανειολήπτη.",
			"Download the app on your mobile to stay updated on your loan application!": "Κατέβασε το App για το κινητό σου για να μην χάσεις καμία ενημέρωση για το αίτημά σου!",
			"The app provides you with all the necessary features, such as": "Η εφαρμογή σου παρέχει όλες τις απαραίτητες δυνατότητες όπως",
			"Submitting a mortgage loan application": "Υποβολή αιτήματος στεγαστικού δανείου",
			"Tracking the status of your application with updates at every stage": "Παρακολούθηση κατάστασης αίτησης με ενημερώσεις σε κάθε στάδιο",
			"Notifications and reminders for required actions": "Ειδοποιήσεις και υπενθυμίσεις για απαιτούμενες ενέργειες",
			"Submitting supporting documents": "Υποβολή δικαιολογητικών",
			"Loan installment calculator with detailed information, reminders, and repayment scheduling.": "Υπολογισμό δόσεων δανείου με αναλυτικές πληροφορίες, υπενθυμίσεις και προγραμματισμό αποπληρωμών.",
			"We collaborate with the largest financial institutions in Greece, offering you the best possible options for mortgage products.": "Συνεργαζόμαστε με τα μεγαλύτερα πιστωτικά ιδρύματα της Ελλάδας παρέχοντάς σου τις καλύτερες δυνατές επιλογές σε προϊόντα στεγαστικού.",
			"Our greatest certification is our customers!": "Η μεγαλύτερη πιστοποίησή μας είναι οι πελάτες μας!",
			"Check out our numerous Google Business reviews that highlight the reliability and effectiveness of IMS.": "Διαβάστε τις δεκάδες κριτικές μας στο Google Bussines που πιστοποιούν την αξιοπιστία και την αποτελεσματικότητα της IMS",
			"Phone Support": "Τηλεφωνική Εξυπηρέτηση",
			"Learn More": "Mάθε περισσότερα",
			"Loan installment calculator": "Υπολογισμός Δόσης Δανείου",
		},
	},
	[Namespaces.WIZARD]: {
		el: {
			"Why choose IMS": "Γιατί με την IMS",
			"Immediate credit assessment": "Άμεση αξιολόγηση",
			"100% cost free support": "100% δωρεάν υποστήριξη",
			"No commitment": "Χωρίς δέσμευση",
			"Zero brokerage fees": "Μηδενικό κόστος διαμεσολάβησης",
			"Download the app": "Κατεβάστε την εφαρμογή",
			"Previous": "Προηγούμενο",
			"Next": "Επόμενο",
			"What will be the purpose of the loan?": "Ποιος θα είναι ο σκοπός του δανείου;",
			"Have you found a property?": "Έχετε βρει ακίνητο;",
			"Yes": "Ναι",
			"No": "Όχι",
			"What is the value of the property you want to complete?": "Ποια είναι η αξία του ακινήτου που θέλετε να αποπερατώσετε;",
			"What is the cost/budget for the required work?": "Ποιό είναι το κόστος /προυπολογισμός των εργασιών που απαιτούνται;",
			"What is the construction cost?": "Ποιό είναι το κόστος της κατασκευής;",
			"What is the value of the property you want to buy/search for?": "Ποιά είναι η αξία του ακινήτου που θέλετε να αγοράσετε/αναζητείτε;",
			"What is the value of the plot you want to buy?": "Ποιά είναι η αξία του οικοπέδου που θέλετε να αγοράσετε;",
			"Property Location": "Περιοχή ακινήτου",
			"In how many months do you want to purchase the property?": "Σε πόσους μήνες θέλετε να αγοράσετε το ακίνητο;",
			"There is no time to waste! It would be a good idea to proceed immediately with a mortgage loan application!": "Δεν υπάρχει χρόνος για χάσιμο! Θα ήταν καλή ιδέα να προχωρήσετε άμεσα με αίτημα στεγαστικού δανείου!",
			"Now is the right time to take the next step, applying for a mortgage.": "Είναι η κατάλληλη στιγμή για να προχωρήσετε στο επόμενο βήμα, την αίτηση για στεγαστικό δάνειο.",
			"It’s a good idea to plan your next steps. By knowing how much money you can borrow, you will be able to chose the property that suits you.": "Καλό είναι να προγραμματίζετε τις επόμενες κινήσεις σας. Γνωρίζοντας πόσα χρήματα μπορείτε να λάβετε θα επιλέξετε και το ακίνητο που σας ταιριάζει",
			"What is your country of residence?": "Ποια είναι η χώρα κατοικίας σας;",
			"What is your nationality?": "Ποια είναι η υπηκοότητα σας;",
			"Are you a tax resident of...": "Είστε φορολογικός κάτοικος...",
			"What is your age?": "Ποια είναι η ηλικία σας;",
			"What loan amount would you like to apply for?": "Τι ποσό δανείου επιθυμείτε να αιτηθείτε;",
			"Property value": "Αξία ακινήτου",
			"Financing percentage or otherwise LTV (Loan to Value)": "Ποσοστό χρηματοδότησης ή αλλιώς LTV",
			ltv_description: "Πρόκειται για ένα ποσοστό το οποίο προκύπτει από: Αιτούμενο Ποσό δανείου/ Εμπορική αξία του ακινήτου",
			"Marital status": "Οικογενειακή κατάσταση",
			"Dependents": "Εξαρτώμενα μέλη",
			"What is your annual net income?": "Ποιο είναι το ετήσιο καθαρό σας εισόδημα",
			"(Enter the total income of all borrowers if there is more than one)": "(καταχωρείστε αθροιστικά τα εισοδήματα όλων των οφειλετών αν είναι πάνω από ένας)",
			"Do you have any other loan obligations?": "Έχετε άλλες δανειακές υποχρεώσεις;",
			"Enter the total amount of monthly installments you are paying.": "Kαταχωρείστε αθροιστικά το ποσό των μηνιαίων δόσεων που πληρώνετε.",
			"Do you have credit cards or open loans? If yes, what is their total credit limit?": "Έχετε πιστωτικές κάρτες ή ανοιχτά δάνεια; Αν ναι ποιο είναι το πιστωτικό τους όριο αθροιστικά;",
			"How did you hear about us?": "Πώς μάθατε για εμάς;",
			"Haven't found a property? IMS can help you find one through its extensive network of collaborating real estate agents.": "Δεν έχετε βρει ακίνητο; Η IMS μπορεί να σας βοηθήσει να βρείτε ακίνητο μέσω του εκτεταμένου δικτύου συνεργαζόμενων μεσιτών της.",
			"Results": "Αποτελέσματα",
			"The loan amount you have applied for is sustainable, and the installment payment will be made without significant pressure on your family income": "Το ποσό δάνειου που προκύπτει είναι βιώσιμο και η πληρωμή της δόσης θα γίνεται χωρίς ιδιαίτερη πίεση για το οικογενειακό σας εισόδημα",
			"The loan amount resulting is viable, but the payment of the installment will put some pressure on your family income": "Το ποσό δανείου που προκύπτει είναι βιώσιμο αλλά η πληρωμή της δόσης θα ασκεί κάποια πίεση για το οικογενειακό σας εισόδημα",
			"The loan amount you have requested is viable, but the payment of the installment will put some pressure on your family income": "Το ποσό δανείου που έχετε αιτηθεί είναι βιώσιμο αλλά η πληρωμή της δόσης θα ασκεί κάποια πίεση για το οικογενειακό σας εισόδημα",
			"The loan amount you have requested is marginally viable, and the payment of the installment will put pressure on your family income": "Το ποσό δανείου που έχετε αιτηθεί είναι οριακά βιώσιμο και η πληρωμή της δόσης θα ασκεί πίεση στο οικογενειακό σας εισόδημα",
			"Unfortunately, you cannot receive pre-approval for a mortgage loan": "Δυστυχώς δεν μπορείτε να λάβετε προέγκριση για στεγαστικό δάνειο",
			"Requested Loan Amount": "Ποσό Αιτούμενου Δανείου",
			"Loan-to-Value Ratio for Property Value": "Ποσοστό Xρηματοδότησης για αξία ακινήτου",
			"Indicative monthly installment for the loan amount": "Ενδεικτική μηνιαία δόση για ποσό δανείου",
			"The maximum amount you can borrow based on the information you provided": "Το μέγιστο ποσό που μπορείτε να δανειστείτε βάσει των στοιχείων που μας δώσατε",
			"Proceed with your request and a consultant will contact you to address all your questions!": "Προχώρηστε το αίτημά σας και ένας σύμβουλος θα επικοινωνήσει μαζί σας για να λύσει όλες τις απορίες σας!",
			"The amount you have requested can be approved! Proceed with the application!": "Το ποσό που έχετε ζητήσει μπορεί να εγκριθεί! Προχωρήστε με αίτηση!",
			"Still have questions? Schedule an appointment.": "Έχετε ακόμα απορίες; Κλείστε ένα ραντεβού.",
			"Full name": "Ονοματεπώνυμο",
			"Email": "Email",
			"Mobile phone": "Κινητό τηλέφωνο",
			"By setting a password, you can create an account to track your application": "Βάζοντας ένα κωδικό μπορείτε να δημιουργήσετε λογαριασμό για την παρακολούθηση του αιτήματός σας",
			"Create a password": "Δημιουργία κωδικού",
			"Confirm password": "Επιβεβαίωση κωδικού",
			"I accept the": "Αποδέχομαι την",
			"Privacy Policy": "Δήλωση Απορρήτου",
			"Enter the code you received in your email": "Εισάγετε τον κωδικό που λάβατε στο email σας",
			"The code will be active for": "O κωδικός θα είναι ενεργός για",
			"Resend": "Επαναποστολή",
			"A new code has been sent in your email": "Στάλθηκε νέος κωδικός στο email σας",
			"Required": "Υποχρεωτικό",
			"Invalid phone number": "Λάθος αριθμός τηλεφώνου",
			"Invalid email": "Μη έγκυρο email",
			"Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character": "Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας",
			"Passwords do not match": "Οι κωδικοί δεν ταιριάζουν",
			"Submit": "Υποβολή",
			"Your application has been successfully submitted, please expect communication from an IMS consultant": "Tο αίτημα σας έχει υποβληθεί με επιτυχία, αναμένετε επικοινωνία από κάποιο σύμβουλο της IMS",
			"Log in to app": "Σύνδεση στην εφαρμογή",
			"Return to homepage": "Επιστροφή στην αρχική σελίδα",
		},
		en: {
			ltv_description: "This is a percentage that results from: Requested Loan Amount / Market Value of the Property",
		}
	}
};

export default locales;
