//@flow
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import useQueryParams from "../../hooks/useQueryParams";
import useUserData from "../../hooks/user/useUserData";
import moment from "moment";
import 'moment/locale/el'
import useUserAction from "../../hooks/user/useUserAction";
import './main.css';
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";
import { Capacitor } from '@capacitor/core';
import Header from '../../thinkPlus/components/elements/Header';

const Main = () => {
	const { i18n } = useTranslation()
	const queryParams = useQueryParams()
	const urlLanguage = queryParams.get('lang')
	const { getUserPermissions, setUserLanguage } = useUserAction()
	const { isLoggedIn, language, alterPasswordOnLogin } = useUserData()
	const platform = Capacitor.getPlatform()
	
	const languageToSet = useMemo(() => (
			urlLanguage && (urlLanguage === 'el' || urlLanguage === 'en') ? urlLanguage : language),
		[language, urlLanguage])
	
	useEffect(() => {
		if (urlLanguage) {
			setUserLanguage(languageToSet)
		}
	}, [languageToSet, setUserLanguage, urlLanguage])
	
	useEffect(() => {
		moment.locale(languageToSet);
		i18n.changeLanguage(languageToSet)
	}, [i18n, language, languageToSet])
	
	useEffect(() => {
		if (isLoggedIn) {
			getUserPermissions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUserPermissions, isLoggedIn])

// Not an optimal way to force lang parameter in all urls
// useEffect(() => {
// 	const urlLanguage = queryParams.get('lang')
// 	if (!urlLanguage || (urlLanguage && urlLanguage !== language && (urlLanguage === 'en' || urlLanguage === 'el'))) {
// 		queryParams.set('lang', language);
// 		history.replace({ search: queryParams.toString() })
// 	}
// }, [history, language, queryParams]);
	
	if (!isLoggedIn || (isLoggedIn && alterPasswordOnLogin)) return (
		<>
			<div>
				{/* <AppBar position={'fixed'} elevation={0}> */}
				{/* <IonHeader className="ion-no-border"> */}
				<Header/>
				{/* </IonHeader> */}
				{/* </AppBar> */}
				{/* <IonContent> */}
				{/* <Box pt={8}> */}
				<UnauthenticatedRouter/>
				{/* </Box> */}
				{/* </IonContent> */}
			</div>
		</>
	)
	return <Content/>
}

export default Main