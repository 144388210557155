// @flow
import Button from "@mui/material/Button";
import React, { useMemo } from 'react';
import { AppBar, List, ListItem, ListItemButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useHasPermission } from "../../modules/permission/hooks/useHasPermission";
import useConfig from "../../theme/mantis/hooks/useConfig";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_LANDING, ROUTE_PAGE_HOME } from "../../routers/routes";
import { ROUTE_PAGE_CUSTOMERS_INDEX } from "../../modules/customers/routes/routes";
import { ROUTE_PAGE_LOANS_INDEX } from "../../modules/loans/routes/routes";
import { ROUTE_PAGE_CALCULATIONS_INDEX } from "../../modules/calculations/routes/routes";
import { useTranslation } from "react-i18next";
import { NAVIGATION } from "../../locales/components/namespaces";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import { Link } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ThemeMode } from "../../theme/mantis/config";
import LogoIcon from "../../theme/mantis/components/logo/logoIcon";
import HomeNative from "../../assets/images/icons/nativeIcons/homeNative";
import CalculatorNative from "../../assets/images/icons/nativeIcons/calculatorNative";
import BankNative from "../../assets/images/icons/nativeIcons/bankNative";
import HeadPhonesNative from "../../assets/images/icons/nativeIcons/headphonesNative";
import UsersBlueIcon from "../../assets/images/icons/menuIcons/usersBlueIcon";
import SupportDialogMobile from "../../pages/supportDialog/supportDialogMobile";

const QuickActionItem = ({ url, icon, theme, isLogoIcon, isSupport }) => {
	const [open, setOpen] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState([]);
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = (value) => {
		setOpen(false);
		setSelectedValue(value);
	};
	return (
		<>
			<ListItem
				sx={{
					width: 10,
					pr: 6
				}}
			>
				<ListItemButton
					component={isSupport ? Button : Link} // Αν είναι support, το κουμπί δε θα χρησιμοποιεί το Link
					to={isSupport ? null : url} // Αν είναι support, δε θα έχει URL
					onClick={() => {
						if (isSupport) {
							handleClickOpen()
						}
					}}
					sx={{
						flexDirection: 'column',
						color: theme.palette.mode === ThemeMode.DARK ? 'black' : 'white'
					}}
					alignItems={'center'}
				>
					<ListItemIcon
						sx={{
							height: isLogoIcon ? 40 : 40,
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: isLogoIcon ? 'none' : '12px',
							display: 'flex',
							padding: '12px',
							border: isLogoIcon ? 'none' : '1px solid #485776',
							background: '#FFF',
						}}
					>
						{icon}
					</ListItemIcon>
				
				</ListItemButton>
			</ListItem>
			<SupportDialogMobile
				selectedValue={selectedValue}
				open={open}
				onClose={handleClose}
			/>
		</>
	)
}

const NativeQuickActions = () => {
	const theme = useTheme();
	const { mode } = useConfig()
	const { t } = useTranslation(NAVIGATION)
	const isPartner = useHasPermission(['access_partner_dashboard'], {shouldView: true})
	
	const items = useMemo(() => [
			{
				id: 1,
				url: isPartner ? getRouteUrl(ROUTE_PAGE_HOME) : getRouteUrl(ROUTE_PAGE_LANDING),
				icon: <LogoIcon/>,
				isLogoIcon: true
			}, {
				id: 2,
				url: getRouteUrl(ROUTE_PAGE_HOME),
				icon: <HomeNative/>,
			},
			{
				id: 3,
				url: getRouteUrl(ROUTE_PAGE_CALCULATIONS_INDEX),
				icon: <CalculatorNative/>,
				permissions: ['access_calculations_index'],
			},
			{
				id: 4,
				url: getRouteUrl(ROUTE_PAGE_CUSTOMERS_INDEX),
				icon: <UsersBlueIcon width={14}
									 height={15}/>,
				permissions: ['access_customer_index'],
			},
			{
				id: 5,
				url: getRouteUrl(ROUTE_PAGE_LOANS_INDEX),
				icon: <BankNative/>,
				permissions: ['access_loan_index'],
			}, {
				id: 6,
				url: '',
				icon: <HeadPhonesNative/>,
				permissions: ['access_support'],
				isSupport: true
			},
		]
		, [t])
	return (
		<AppBar
			sx={{
				top: 'auto',
				bottom: 0,
				bgcolor: mode === ThemeMode.LIGHT ? '#fff' : theme.palette.primary.main,
				width: '100%',
				height: 90,
				justifyContent: 'center',
				zIndex: 1098,
				flexDirection: 'row',
			}}
		>
			<List
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					mb: 1
				}}
			>
				{
					items.map(({
								   id,
								   icon,
								   url,
								   title,
								   permissions,
								   isLogoIcon,
								   isSupport
							   }) => permissions && permissions.length > 0 ? (
						<PermissionHOC
							key={id}
							requiredPermissions={[...permissions]}
						>
							<QuickActionItem
								icon={icon}
								url={url}
								title={title}
								theme={theme}
								isLogoIcon={isLogoIcon}
								isSupport={isSupport}
							/>
						</PermissionHOC>
					) : (
						<QuickActionItem
							key={id}
							icon={icon}
							url={url}
							title={title}
							theme={theme}
							isLogoIcon={isLogoIcon}
							isSupport={isSupport}
						/>
					))}
			</List>
		</AppBar>
	);
};

export default NativeQuickActions